import { Controller, useFormContext } from "react-hook-form";
import FieldWithError from "components/FieldWithError";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import _ from "lodash";
import { ProjectStage } from "gql/graphql";
import { standardErrorMessage, ariaProps } from "utils/forms";
import { TextFieldElement, TextFieldElementProps } from "react-hook-form-mui";
import AttachFile, { AllowedFileTypes } from "components/AttachFile";

const DefaultTextFieldElement = (props: TextFieldElementProps) => (
  <TextFieldElement fullWidth variant="outlined" size="small" {...props} />
);

export const ProjectMetadataFields = () => {
  const {
    register,
    formState: { errors },
    control,
    setError,
    setValue,
    clearErrors,
  } = useFormContext();

  return (
    <>
      <div className="project-metadata-field">
        <InputLabel htmlFor="project-name">Project Name</InputLabel>
        <DefaultTextFieldElement
          required
          name="projectName"
          autoComplete="off"
          id="project-name"
        />
      </div>
      <div className="project-metadata-field">
        <InputLabel htmlFor="project-cover-image">Cover Image</InputLabel>
        <FieldWithError
          className="attach-file"
          errorMessage={errors.coverImageFileId?.message as string}
          fieldElement={
            <AttachFile
              acceptFileTypes={AllowedFileTypes.IMAGE}
              handleError={(e: string) => {
                setError("coverImageFileId", {
                  message: `Error uploading file: ${e}`,
                });
              }}
              onSuccessfulUpload={(coverImageBlobId: string) => {
                setValue("coverImageFileId", coverImageBlobId);
                clearErrors("coverImageFileId");
              }}
              onFileRemove={() => setValue("coverImageFileId", "")}
            />
          }
        />
      </div>

      <div className="project-metadata-field">
        <InputLabel htmlFor="project-description">Description</InputLabel>
        <FormControl fullWidth>
          <FieldWithError
            errorMessage={standardErrorMessage(errors, "description")}
            fieldElement={
              <TextField
                fullWidth
                id="project-description"
                variant="outlined"
                placeholder="Description"
                helperText="Optional"
                size="small"
                inputProps={{
                  ...ariaProps(errors, "description"),
                }}
                {...register("description")}
              />
            }
          />
        </FormControl>
      </div>

      <div className="project-metadata-field">
        <FormControl fullWidth>
          <FieldWithError
            errorMessage={standardErrorMessage(errors, "includeInPortfolio")}
            fieldElement={
              <Controller
                name="includeInPortfolio"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        id="project-include-in-portfolio"
                        checked={field.value}
                        size="small"
                        inputProps={{
                          ...ariaProps(errors, "includeInPortfolio"),
                        }}
                        {...register("includeInPortfolio")}
                      />
                    }
                    label="Include in Portfolio Dashboard"
                  />
                )}
              />
            }
          />
        </FormControl>
      </div>
    </>
  );
};

export default ProjectMetadataFields;
