import { Typography } from "@mui/material";
import { DataLoadingContext } from "context/DataLoadingContext";
import {
  AreaUnit,
  FloorGroupDefinition,
  LciaMethodEnum,
  Maybe,
} from "gql/graphql";
import _ from "lodash";
import numeral from "numeral";
import { Fragment, useContext } from "react";

export const DATE_STRING = "YYYY-MM-DD";
export const DATE_STRING_NICE = "MM/D/YY";
export const MM_YYYY_STRING = "MM/YYYY";
export const YYYY_MM_STRING = "YYYY-MM";
export const DATE_PICKER_FORMAT = "MMM YYYY";

export const DATE_TIME_STRING = "MM/D/YY h:mm a";

export type KGCO2EProps = {
  bold?: boolean;
  kgCo2e?: number | null;
  numFormat?: string;
  separator?: "/" | " per ";
  unit?: Maybe<AreaUnit>;
  withParentheses?: boolean;
};

export const TitleWithKGCO2E = ({
  title,
  ...rest
}: {
  title: string;
} & KGCO2EProps) => {
  const { dataLoading } = useContext(DataLoadingContext);

  if (dataLoading) return null;
  return (
    <>
      {title}
      <Typography component="span" variant="body2" className="kgco2e">
        &nbsp;
        <KGCO2E {...rest} />
      </Typography>
    </>
  );
};

export const KGCO2E = ({
  bold,
  kgCo2e,
  unit,
  numFormat = KGCO2E_FORMAT,
  withParentheses = false,
  separator = " per ",
}: KGCO2EProps) => {
  const start = <Fragment key="start">{withParentheses ? "(" : ""}</Fragment>;
  const end = <Fragment key="end">{withParentheses ? ")" : ""}</Fragment>;
  const unitString = unit && (
    <Fragment key="unit">
      {separator}
      {formatAreaUnit(unit)}
    </Fragment>
  );
  const co2e = (
    <Fragment key="co2e">
      CO
      <sub>2</sub>e{unitString}
    </Fragment>
  );
  const wrappedNumber = (
    <span key="wrapped" className={bold ? "bold" : ""}>
      {!_.isUndefined(kgCo2e) &&
        `${numeral(kgCo2e).format(numFormat || "0,0.0 ")} `}
    </span>
  );
  const kg = <Fragment key="kg">kg&nbsp;</Fragment>;

  return <>{[start, wrappedNumber, kg, co2e, end]}</>;
};

export const processFilename = (filename: string) =>
  _.last(_.split(filename, /\\|\//));

export const stringToNumber = (num?: string | number) =>
  Number(
    // Allow commas in area, but strip them out before submitting
    _.isString(num) ? (num as String).replaceAll(",", "") : num
  );

export const formatAreaUnit = (unit: AreaUnit) => {
  let formattedUnit = _.toLower(unit);
  if (_.endsWith(unit, "2")) {
    return formattedUnit.replace("2", "²");
  }
  return formattedUnit;
};

export const formatUnitWithScalar = (string: string) => {
  let formattedUnit = _.toLower(string).replace(/\^([2-3])/g, "$1");
  if (_.endsWith(formattedUnit, "2")) {
    formattedUnit = formattedUnit.replace("2", "²");
  }
  if (_.endsWith(formattedUnit, "3")) {
    formattedUnit = formattedUnit.replace("3", "³");
  }
  formattedUnit = formattedUnit.replace(/^1 /, "");
  return _.trim(formattedUnit);
};

export const REPORTING_STAT_FORMAT = "0,0";
export const KGCO2E_FORMAT = "0,0";

type LifecycleStage = "A1" | "A2" | "A3" | "A4" | "A5";
export const EmissionsText = ({
  from = "A1",
  to = "A5",
}: {
  from: LifecycleStage;
  to: LifecycleStage;
}) => (
  <>
    {from} &ndash; {to} emissions
  </>
);

export const lciaMethodFormatLookup = {
  [LciaMethodEnum.Cml]: "CML",
  [LciaMethodEnum.Cml_2001]: "CML 2001",
  [LciaMethodEnum.Cml_2007]: "CML 2007",
  [LciaMethodEnum.Cml_2012]: "CML 2012",
  [LciaMethodEnum.Cml_2016]: "CML 2016",
  [LciaMethodEnum.Ef_3_0]: "EF 3.0",
  [LciaMethodEnum.IpccAr5]: "IPCC AR5",
  [LciaMethodEnum.ReCiPe_2016]: "ReCiPe 2016",
  [LciaMethodEnum.Traci_2_1]: "TRACI 2.1",
  [LciaMethodEnum.Traci_2_1V1_02]: "TRACI 2.1 V1.02",
  [LciaMethodEnum.UnknownLcia]: "Unknown LCIA",
};

// adapted from https://stackoverflow.com/a/11832950
export const roundToDigits = (value: number, digits: number) => {
  const factor = Math.pow(10, digits);
  return Math.round((value + Number.EPSILON) * factor) / factor;
};

// Compute cross floor area from form 'floor groups' fields
export const safeComputeGFA = (
  aboveGroundFloors: Maybe<FloorGroupDefinition>,
  belowGroundFloors?: Maybe<FloorGroupDefinition>
) => {
  const aboveArea = aboveGroundFloors?.areaPerFloor.area;
  const aboveCount = aboveGroundFloors?.count;
  const belowArea = belowGroundFloors?.areaPerFloor.area;
  const belowCount = belowGroundFloors?.count;

  let total = 0;
  if (aboveArea && aboveCount) {
    total += aboveArea * aboveCount;
  }

  if (belowArea && belowCount) {
    total += belowArea * belowCount;
  }
  return Math.max(total, 0);
};
