import { gql } from "@apollo/client";

export const GET_ALL_MANUFACTURERS = gql`
  query getAllManufacturers($filter: ManufacturerFilterInput) {
    catalog {
      manufacturers(filter: $filter) {
        nodes {
          name
          id
        }
      }
    }
  }
`;

export const GET_PLANTS_FOR_MANUFACTURER = gql`
  query getManufacturerPlants($manufacturerId: ID!) {
    catalog {
      manufacturer(id: $manufacturerId) {
        name
        id
        plants(filter: { withAddresses: true }) {
          nodes {
            name
            id
            address {
              shortAddress
            }
          }
        }
      }
    }
  }
`;
