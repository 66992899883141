import { ReactNode, useCallback, useContext, useEffect, useState } from "react";

import _ from "lodash";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";
import { Controller, useFormContext } from "react-hook-form";
import provinces from "provinces";

import FieldWithError from "components/FieldWithError";
import { BlockInteractionContext } from "context/BlockInteractionContext";
import { CustomErrorMessages } from "pages/CreateProject/Components/Form/CustomErrorMessages";
import { customMessages } from "pages/CreateProject/GuidedProjectOnboarding/constants";
import { standardErrorMessage, ariaProps } from "utils/forms";
import {
  extractAddressComponents,
  geocodeAddress,
  ingestGoogleAddress,
} from "../utils";
import { GOOGLE_MAPS_API_KEY } from "../../../constants";
import "./AddressInputFields.scss";
import { getCountryDataList } from "countries-list";

const StatesUS = _.filter(provinces, (p) => p.country === "US");
const ProvincesCA = _.filter(provinces, (p) => p.country === "CA");

export const streetAddressInputId = "street-address-input-id";

export const AddressInputFields = ({
  hideLabel = false,
  addressInputId = streetAddressInputId,
  validateLatitude = true,
}: {
  addressInputId?: string;
  hideLabel?: boolean;
  validateLatitude?: boolean;
}) => {
  const {
    register,
    setValue,
    watch,
    control,
    clearErrors,
    setError,

    formState: { isDirty, errors },
  } = useFormContext();
  const { blocked: interactionBlocked } = useContext(BlockInteractionContext);
  const disableAnimation = !isDirty;

  const { ref, isScriptLoaded } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (apiResponse) => {
      clearErrors("address.postalCode");
      clearErrors("address.latitude");
      clearErrors("address.longitude");
      const results = extractAddressComponents(apiResponse);
      ingestGoogleAddress(results, setValue, clearErrors);
    },
    libraries: ["places", "geocoding"],
    options: {
      types: ["geocode"],
    },
  });

  const clearValue: EventListenerOrEventListenerObject = (event: Event) => {
    ((event as InputEvent).target! as HTMLInputElement).value = "";
  };
  useEffect(() => {
    if (!ref || !ref.current) return;
    const input = ref.current! as HTMLInputElement;
    input.addEventListener("focus", clearValue);
    return () => input.removeEventListener("focus", clearValue);
  }, [ref]);

  const countrySwitch =
    (onChange: Function) =>
    (event: SelectChangeEvent<any>, child: ReactNode) => {
      const current = event.target.value;
      const prev = countryCode;
      if (prev && current !== prev) {
        setValue("address.city", "");
        setValue("address.street1", "");
        setValue("address.stateCode", "");
        setValue("address.postalCode", "");
        setValue("address.latitude", "");
        setValue("address.longitude", "");
        (ref.current! as HTMLInputElement).value = "";
      }
      onChange(event);
    };

  const street = watch("address.street1");
  const countryCode = watch("address.countryCode");
  const postalCodeName = countryCode === "US" ? "Zipcode" : "Postal Code";
  const stateCodeName = countryCode === "CA" ? "Province" : "State";
  const subdivisions = countryCode === "CA" ? ProvincesCA : StatesUS;
  const requireStateCode = countryCode === "US" || countryCode === "CA";
  const postalCode = watch("address.postalCode");

  const geocodeFields = watch([
    "address.countryCode",
    "address.postalCode",
    "address.stateCode",
    "address.city",
  ]);

  const [initialGeocode, setInitialGeocode] = useState(geocodeFields);

  useEffect(() => {
    if (!isScriptLoaded) return;
    if (
      _.isEqual(geocodeFields, initialGeocode) ||
      _.every(geocodeFields, (v) => !v)
    )
      return;

    const setAddress = async () => {
      try {
        const geoResult = await geocodeAddress(
          postalCode || "",
          setValue,
          clearErrors
        );
        setValue("address.latitude", geoResult.latitude);
        setValue("address.longitude", geoResult.longitude);
        clearErrors(["address.latitude", "address.longitude"]);
      } catch (error) {
        // ZERO_RESULTS: No result was found for this GeocoderRequest.
        setValue("address.latitude", "");
        setValue("address.longitude", "");
        setError("address.latitude", {
          message: "Invalid address",
        });
        setError("address.longitude", {
          message: "Invalid address",
        });
      }
    };

    setAddress();

    setInitialGeocode(geocodeFields);
  }, [initialGeocode, geocodeFields, isScriptLoaded]);

  return (
    <div className="address-fields">
      {!hideLabel && <InputLabel htmlFor="country-select">Location</InputLabel>}
      <div id="address-with-autocomplete">
        <input
          // @ts-ignore
          ref={ref}
          id={addressInputId}
          autoComplete="off"
          name="street-address"
          className="google-places-autocomplete-input"
          defaultValue={street}
          placeholder="Street Address"
          disabled={interactionBlocked}
        />
      </div>
      <div className="address-row">
        <FieldWithError
          className="address-input-city"
          errorMessage={standardErrorMessage(errors, "address.city")}
          fieldElement={
            <FormControl className="city">
              <TextField
                id="address-input-section-city"
                label="City"
                type="text"
                InputLabelProps={{
                  disableAnimation,
                }}
                size="small"
                inputProps={{
                  ...ariaProps(errors, "address.city"),
                }}
                {...register("address.city")}
                disabled={interactionBlocked}
                value={watch("address.city") || ""}
              />
            </FormControl>
          }
        />
        <FieldWithError
          className="address-input-zipcode"
          errorMessage={
            standardErrorMessage(errors, "address.postalCode") ||
            standardErrorMessage(errors, "address.latitude")
          }
          fieldElement={
            <FormControl className="zipcode">
              <TextField
                type="text"
                label={postalCodeName}
                size="small"
                id="postalCode"
                InputLabelProps={{
                  disableAnimation,
                }}
                inputProps={{
                  autoComplete: "off",
                  ...ariaProps(errors, "address.postalCode"),
                }}
                {...register("address.postalCode", {
                  required: true,
                })}
                disabled={interactionBlocked}
                value={postalCode || ""}
              />
            </FormControl>
          }
        />
        <FormControl fullWidth size="small">
          <Controller
            name="address.countryCode"
            control={control}
            render={({ field, field: { onChange } }) => (
              <>
                <InputLabel htmlFor="country-select">Country</InputLabel>
                <Select
                  type="text"
                  size="small"
                  placeholder="Country"
                  {...field}
                  value={countryCode || ""}
                  inputProps={{
                    id: "country-select",
                    ...ariaProps(errors, "address.countryCode"),
                  }}
                  onChange={countrySwitch(onChange)}
                  disabled={interactionBlocked}
                >
                  {_.sortBy(getCountryDataList(), "name").map((country) => (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          />
        </FormControl>
        {requireStateCode && (
          <FieldWithError
            className="address-input-state"
            errorMessage={standardErrorMessage(errors, "stage")}
            fieldElement={
              <>
                <FormControl size="small">
                  <InputLabel htmlFor="address-input-state-select">
                    {stateCodeName}
                  </InputLabel>
                  <Select
                    inputProps={{
                      id: "address-input-state-select",
                    }}
                    size="small"
                    placeholder={stateCodeName}
                    {...register("address.stateCode")}
                    disabled={interactionBlocked}
                    value={watch("address.stateCode") || ""}
                  >
                    {_.map(subdivisions, (subdivision) => (
                      <MenuItem
                        key={subdivision.short}
                        value={subdivision.short}
                      >
                        {subdivision.short}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            }
          />
        )}

        <input
          type="hidden"
          {...register("address.latitude", { required: validateLatitude })}
        />
        <input
          type="hidden"
          {...register("address.longitude", { required: validateLatitude })}
        />
      </div>
      <CustomErrorMessages
        name="address.latitude"
        customMessages={customMessages.address.latitude}
        errors={errors}
      />
    </div>
  );
};

export default AddressInputFields;
