import Box from "@mui/material/Box";

export const a11yProps = <T extends {}>({
  tabName,
  parentId,
}: {
  parentId: "project-report" | "my-projects" | "edit-project-modal";
  tabName: T;
}) => ({
  id: `${parentId}-tab-${tabName}`,
  "aria-controls": `${parentId}-tabpanel-${tabName}`,
});

interface TabPanelProps<T> {
  currentTab: T;
  parentId: string;
  tabName: T;
  children?: React.ReactNode;
  className?: string;
}

const TabPanel = <TN,>(props: TabPanelProps<TN>) => {
  const { children, currentTab, tabName, parentId, ...other } = props;
  const show = currentTab === tabName;

  return (
    <div
      role="tabpanel"
      hidden={!show}
      id={`${parentId}-tabpanel-${tabName}`}
      aria-labelledby={`${parentId}-tab-${tabName}`}
      {...other}
    >
      {show && <Box>{children}</Box>}
    </div>
  );
};

export default TabPanel;
