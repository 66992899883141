import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";

import { Maybe } from "gql/graphql";
import FieldWithError from "components/FieldWithError";
import { standardErrorMessage } from "utils/forms";

import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import _ from "lodash";

const filter = createFilterOptions<OptionType>();

interface OptionType {
  id?: Maybe<string>;
  inputValue?: string;
  name?: Maybe<string>;
}
interface AutocompleteDropdownProps {
  fieldName: string;
  label: string;
  onSelect: any;
  options: OptionType[];
  value: OptionType | null;
  customRenderOption?: any;
  onSearch?: Function;
}

/*
  This is a special version of the Autocomplete component (freeSolo)
      https://mui.com/material-ui/react-autocomplete/#free-solo
  that allows the user to create a new option
  It is used for creating a new manufacturer, as well as in conjunction with the CreatePlantModal, which is more complex
  because the user needs to add an address to the plant as well
*/

const AutocompleteDropdown = ({
  fieldName,
  label,
  options: initialOptions,
  value,
  onSelect,
  customRenderOption,
  onSearch,
}: AutocompleteDropdownProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [options, setOptions] = useState<OptionType[]>(initialOptions);

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  const throttledSearch = useRef(
    _.throttle(async (input: string) => {
      const newOptions = await onSearch?.(input);
      setOptions(newOptions || []);
    }, 500)
  );

  const handleInputChange = useCallback(
    (event: any, inputValue: string) => {
      if (inputValue) {
        throttledSearch.current(inputValue);
      }
    },
    [throttledSearch]
  );

  const handleChange = useCallback(
    (event: any, newValue: any) => {
      if (newValue?.inputValue) {
        onSelect({ name: newValue.inputValue }); // Handle when a new option is created
      } else {
        onSelect(newValue); // Handle when an existing item is selected
      }
    },
    [onSelect]
  );

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: any) => {
      return <li {...props}>{option.name}</li>;
    },
    []
  );

  return (
    <FieldWithError
      errorMessage={standardErrorMessage(errors, fieldName)}
      fieldElement={
        <Controller
          name={fieldName}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              clearOnBlur
              freeSolo
              handleHomeEndKeys
              selectOnFocus
              size="small"
              value={value}
              onChange={handleChange}
              onInputChange={handleInputChange} // Trigger the search only when typing
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.name
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    name: `Add new ${fieldName}: "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              id={`${label}-select-or-create`}
              options={options}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name as string;
              }}
              renderOption={customRenderOption || renderOption}
              renderInput={(params) => <TextField {...params} label={label} />}
            />
          )}
        />
      }
    />
  );
};

export default AutocompleteDropdown;
