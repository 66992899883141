import _ from "lodash";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import { PropertyUse } from "gql/graphql";
import { useFormContext } from "react-hook-form";

import AttachFile, { AllowedFileTypes } from "components/AttachFile";
import FieldWithError from "components/FieldWithError";
import { CustomErrorMessages } from "../Components/Form/CustomErrorMessages";
import { useContext } from "react";
import { BlockInteractionContext } from "context/BlockInteractionContext";
import AddressInputFields, {
  streetAddressInputId,
} from "components/EditProjectForm/components/AddressInputFields";
import {
  HorizontalRadioGroup,
  makeFirstRadioID,
} from "../Components/Form/HorizontalRadioGroup";
import { PROPERTY_USE_ICONS } from "../GuidedProjectOnboarding/constants";
import ProjectTimeline from "components/ProjectTimeline";

export const Page1 = () => {
  const {
    formState: { errors },
    register,
    setError,
    setValue,
    clearErrors,
  } = useFormContext();
  const { blocked: interactionBlocked } = useContext(BlockInteractionContext);
  return (
    <>
      <section id="name">
        <InputLabel htmlFor="project-name-id">Project name</InputLabel>
        <FieldWithError
          fieldElement={
            <FormControl fullWidth className="project-name">
              <TextField
                size="small"
                autoComplete="off"
                id="project-name-id"
                disabled={interactionBlocked}
                {...register("name", { required: true })}
              />
            </FormControl>
          }
        />
        <CustomErrorMessages holdPlace name="name" errors={errors} />
        <div id="cover-image">
          <InputLabel htmlFor="cover-image">
            Cover image <span className="optional">(optional)</span>
          </InputLabel>
          <FieldWithError
            errorMessage={errors.coverImageFileId?.message as string}
            className="attach-file"
            fieldElement={
              <AttachFile
                handleError={(e: string) => {
                  setError("coverImageFileId", {
                    message: `Error uploading file: ${e}`,
                  });
                }}
                onSuccessfulUpload={(coverImageBlobId: string) => {
                  setValue("coverImageFileId", coverImageBlobId);
                  clearErrors("coverImageFileId");
                }}
                onFileRemove={() => setValue("coverImageFileId", "")}
                acceptFileTypes={AllowedFileTypes.IMAGE}
              />
            }
          />
        </div>
      </section>

      <ProjectTimeline />

      <section id="propertyUse">
        <InputLabel
          htmlFor={makeFirstRadioID("propertyUse")}
          className="large-label"
        >
          Asset type
        </InputLabel>
        <FormHelperText>
          The typical designs of different asset types can affect the total
          carbon emissions.
        </FormHelperText>
        <FormControl fullWidth>
          <HorizontalRadioGroup
            name="propertyUse"
            values={_.values(PropertyUse)}
            icons={PROPERTY_USE_ICONS}
            rules={{ required: true }}
          />
        </FormControl>
      </section>
      <section id="address">
        <InputLabel htmlFor={streetAddressInputId} className="large-label">
          Address <span className="optional">(only postal code required) </span>
        </InputLabel>
        <AddressInputFields hideLabel />
      </section>
    </>
  );
};
