import { HTMLAttributes, useCallback, useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Address, Plant } from "gql/graphql";

import {
  GET_ALL_MANUFACTURERS,
  GET_PLANTS_FOR_MANUFACTURER,
} from "graphql/queries/manufacturers";
import CreatePlantForm from "./CreatePlantModal";
import AutocompleteDropdown from "./AutocompleteDropdown";
import "./PlantManufacturerFields.scss";

const SelectOrCreatePlant = ({ plantOptions }: { plantOptions: Plant[] }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { setValue, control } = useFormContext();

  const { field: plantField } = useController({
    name: "plant",
    control,
    defaultValue: null,
  });

  const [modalValue, setModalValue] = useState<{
    address: Address | null;
    name: string;
  }>({
    name: "",
    address: null,
  });

  const handleClose = () => {
    setModalValue({
      name: "",
      address: null,
    });
    setModalOpen(false);
  };

  const handleSubmit = (data: Partial<Plant>) => {
    setValue("plant", data); // set plant field for the parent UploadEPD form
    handleClose();
  };

  const onSelect = (newValue: Plant) => {
    // selected existing plant
    if (newValue.id) {
      plantField.onChange(newValue);
    } else {
      // needs to create new plant with address
      setModalValue(newValue as any);
      setModalOpen(true);
    }
  };

  const customDropdownRenderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: any) => {
      return (
        <li {...props}>
          {option.name}
          &nbsp;
          {option.address && (
            <span style={{ color: "#727272" }}>
              ({option.address.shortAddress})
            </span>
          )}
        </li>
      );
    },
    []
  );

  return (
    <>
      {/* User can select a plant OR */}
      <AutocompleteDropdown
        fieldName="plant"
        label="Plant"
        options={plantOptions}
        value={plantField.value}
        onSelect={onSelect}
        customRenderOption={customDropdownRenderOption}
      />
      {/*  create a new plant */}
      <CreatePlantForm
        modalValue={modalValue}
        modalOpen={modalOpen}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const SelectOrCreateManufacturer = () => {
  const { data: manufacturerData, refetch } = useQuery(GET_ALL_MANUFACTURERS);
  const { control } = useFormContext();

  const { field: manufacturerField } = useController({
    name: "manufacturer",
    control,
    defaultValue: null,
  });

  const search = async (input: string) => {
    const { data } = await refetch({
      filter: { q: input },
    });
    return data?.catalog.manufacturers.nodes || [];
  };

  return (
    <AutocompleteDropdown
      fieldName="manufacturer"
      label="Manufacturer"
      options={manufacturerData?.catalog.manufacturers.nodes || []}
      value={manufacturerField.value}
      onSelect={manufacturerField.onChange}
      onSearch={search}
    />
  );
};
const PlantManufacturerFields = () => {
  const [getPlants, { data: plantData }] = useLazyQuery(
    GET_PLANTS_FOR_MANUFACTURER
  );

  const { watch } = useFormContext();

  const manufacturer = watch("manufacturer");
  useEffect(() => {
    if (manufacturer?.id) {
      getPlants({
        variables: { manufacturerId: manufacturer.id },
      });
    }
  }, [manufacturer?.id, getPlants]);

  return (
    <div id="plant-manufacturer-fields">
      <fieldset className="row">
        <SelectOrCreateManufacturer />

        {manufacturer && (
          <SelectOrCreatePlant
            plantOptions={plantData?.catalog.manufacturer?.plants?.nodes || []}
          />
        )}
      </fieldset>
    </div>
  );
};

export default PlantManufacturerFields;
