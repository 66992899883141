import dayjs from "dayjs";
import _ from "lodash";
import { useFormContext } from "react-hook-form";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { MonthInput } from "components/MonthInput";
import { ariaProps, standardErrorMessage } from "utils/forms";
import FieldWithError from "./FieldWithError";
import { ProjectStage } from "gql/graphql";
import "./ProjectTimeline.scss";

const ProjectStages = [
  ProjectStage.Concept,
  ProjectStage.Design,
  ProjectStage.Construction,
];

const ProjectTimeline = ({ hideLabel = false }: { hideLabel?: boolean }) => {
  const {
    watch,
    getValues,
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <section className="project-dates">
      {!hideLabel && <Typography variant="h6"> Timeline</Typography>}
      <Grid container flexDirection="row" gap={2} pt={2}>
        <Grid item xs={6} md={3}>
          <MonthInput
            name="startedAt"
            id="started-at"
            label="Start of planning"
            controllerProps={{
              rules: {
                required: "Start date is required",
                validate: {
                  beforeCompletedAt: (value: dayjs.Dayjs) => {
                    const completedAt = getValues("completedAt");
                    if (!completedAt) return true;
                    return dayjs(value).isBefore(completedAt);
                  },
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <MonthInput
            name="completedAt"
            id="completion-at"
            label="Construction complete"
            controllerProps={{
              rules: {
                required: "Completion date is required",
              },
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <InputLabel htmlFor="stage">Project stage</InputLabel>
          <FieldWithError
            fieldElement={
              <FormControl fullWidth>
                <Select
                  id="stage"
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...ariaProps(errors, "stage"),
                  }}
                  value={watch("stage")}
                  {...register("stage", { required: true })}
                  fullWidth
                >
                  {_.map(ProjectStages, (stage) => (
                    <MenuItem key={stage} value={stage}>
                      {_.upperFirst(_.toLower(stage))}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
            errorMessage={standardErrorMessage(errors, "stage")}
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default ProjectTimeline;
