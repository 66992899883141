import _ from "lodash";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import {
  CarbonInput,
  CertificationInput,
  LciaMethodEnum,
  ProductCreateInput,
  TotalImpactByStageInput,
} from "gql/graphql";
import { DATE_STRING } from "utils/formatting";
import { processAddress } from "pages/CreateProject/GuidedProjectOnboarding/utils";

export type UploadEpdInput = Omit<ProductCreateInput, "certifications"> & {
  carbon: {
    lciaMethod: LciaMethodEnum;
    totalImpactByStage: TotalImpactByStageInput[];
  };
  certification: CertificationInput;
};

const transformCarbonField = (carbonAsInput: {
  lciaMethod: "" | LciaMethodEnum;
  totalImpactByStage: TotalImpactByStageInput[];
}): CarbonInput => {
  const { lciaMethod, totalImpactByStage } = carbonAsInput;

  // lciaMethod is optional
  const formattedObj = lciaMethod === "" ? {} : { lciaMethod };
  return {
    ...formattedObj,
    totalImpactByStage: _.filter(
      totalImpactByStage,
      ({ kgCo2e }) => _.isFinite(kgCo2e) // isNumber keeps NaN values
    ),
  };
};

export const transformProductInputForMutation = (
  input: UploadEpdInput
): ProductCreateInput => {
  // lciaMethod is the only optional field
  const emptyValsRemoved = _.omitBy(
    input,
    (val, key) => key === "carbon" && !val
  );

  const { carbon, certification, ...rest } =
    emptyValsRemoved as unknown as UploadEpdInput;

  return {
    ...rest,
    manufacturer: {
      ..._.pick(input.manufacturer, ["id", "name"]),
      sourceId: uuidv4(),
    },
    plant: {
      ..._.pick(input.plant, ["id", "name"]),
      address: processAddress(input.plant.address),
      sourceId: uuidv4(),
    },
    certifications: [
      {
        ...certification,
        issuedAt: dayjs(certification.issuedAt).format(DATE_STRING),
        expiresAt: dayjs(certification.expiresAt).format(DATE_STRING),
      },
    ],
    carbon: transformCarbonField(carbon),
  };
};
