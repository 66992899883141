import { Navigate, Route } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";

import NotFound from "components/NotFound";
import PermissionRestricted from "components/PermissionRestricted";
import { Title } from "context/TitleContext";
import { UserPermissionsEnum } from "gql/graphql";
import CreateProject from "pages/CreateProject/CreateProject";
import MyProjects from "pages/MyProjects/MyProjects";
import Portfolio from "pages/Portfolio/Portfolio";
import ProductDetails from "pages/ProductDetails/ProductDetails";
import ProductsCatalog from "pages/ProductsCatalog/ProductsCatalog";
import ProjectReport from "pages/ProjectReport/ProjectReport";
import Settings from "pages/Settings/Settings";
import { FnRender } from "utils/UtilityComponents";

import { ExternalLinks } from "../constants";
import { LogoutComponent } from "./LogoutComponent";

const paths = {
  Root: "/",
  MyProjects: "/my-projects",
  Portfolio: "/portfolio",
  Products: "/products",
  Settings: "/settings",
};

export const LoggedIn = (
  <>
    {/* 👇️ redirect to /products when user goes to / */}
    <Route path={paths.Root} element={<Navigate to={paths.Portfolio} />} />
    <Route
      path={paths.Portfolio}
      element={
        <Title title="Portfolio Insights">
          <PermissionRestricted
            to={UserPermissionsEnum.ViewPortfolio}
            fallback={<Navigate to={paths.MyProjects} />}
          >
            <Portfolio />
          </PermissionRestricted>
        </Title>
      }
    />

    <Route path={paths.Products}>
      <Route
        path=""
        /* 👇️ redirect to /products when user goes to / */
        element={<Navigate to={paths.Products + "/all"} />}
      />
      <Route
        path="all"
        element={
          <Title title="Products Database">
            <ProductsCatalog />
          </Title>
        }
      />
      <Route
        path=":slug"
        element={
          <ErrorBoundary fallback={<NotFound entity="product" />}>
            <ProductDetails />
          </ErrorBoundary>
        }
      />
    </Route>

    {/* My Projects */}
    <Route path={paths.MyProjects}>
      <Route
        index
        element={
          <Title title="Projects">
            <MyProjects />
          </Title>
        }
      />
      <Route path="create" element={<Navigate replace to="page-1" />} />
      <Route
        path="create/:page"
        element={
          <Title title="Create Project">
            <CreateProject />
          </Title>
        }
      />
      <Route path=":projectSlug">
        <Route
          path=":projectSubPage"
          element={
            <ErrorBoundary fallback={<NotFound entity="project" />}>
              <ProjectReport />
            </ErrorBoundary>
          }
        />
        <Route index element={<Navigate replace to="dashboard" />} />
      </Route>
    </Route>

    {/* Resources @deprecated */}
    <Route path="resources">
      <Route
        index
        element={
          // React Router doesn't support opening external links
          <FnRender>
            {() => {
              window.location.href = ExternalLinks.Methodology;
              return null;
            }}
          </FnRender>
        }
      />
    </Route>
    <Route
      path="settings/*"
      element={
        <Title title="Settings">
          <Settings />
        </Title>
      }
    />
    <Route path="reset-password/*" element={<LogoutComponent />} />
    <Route path="login" element={<Navigate to="/" />} />

    {/* 👇️ only match this when no other routes match! */}
    <Route
      path="*"
      element={
        <Title title="Not Found">
          <NotFound entity="page" />
        </Title>
      }
    />
  </>
);
