import _ from "lodash";
import { useCallback, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { Button } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";

import useFileUploadProgress from "context/FileUploadProgress";
import { UploadedFile } from "components/UploadedFile";
import { DirectUploadEnum, FileInfoForUpload } from "gql/graphql";
import "./AttachFile.scss";

export enum AllowedFileTypes {
  PDF,
  IMAGE,
}

const FILE_TYPE_LOOKUP = {
  [AllowedFileTypes.PDF]: {
    "application/pdf": [".pdf"],
  },
  [AllowedFileTypes.IMAGE]: {
    "image/*": [
      ".jpg",
      ".jpeg",
      ".png",
      ".gif",
      ".bmp",
      ".svg",
      ".webp",
      ".tiff",
    ],
  },
};

interface Props {
  handleError: (e: string) => void;
  onFileRemove: () => void;
  onSuccessfulUpload: (epdFileBlobId: string) => void;
  acceptFileTypes?: AllowedFileTypes;
}

const AttachFile = ({
  handleError,
  onFileRemove,
  onSuccessfulUpload,
  acceptFileTypes = AllowedFileTypes.PDF,
}: Props) => {
  const [filename, setFilename] = useState<string>("");

  const progressBarRef = useRef<HTMLDivElement>(null);

  const _onSuccessfulUpload = useCallback(
    (filesForUpload: FileInfoForUpload[]) => {
      // continuing to support only one file for EPD upload for now -- we can adapt in the future
      const firstFile = _.first(filesForUpload);

      if (firstFile) {
        onSuccessfulUpload(firstFile.id);
        setFilename(firstFile.filename);
      }
    },
    [onSuccessfulUpload, setFilename]
  );

  const { performUpload, uploading } = useFileUploadProgress({
    handleError,
    onSuccessfulUpload: _onSuccessfulUpload,
    progressBarRef,
    uploadType: DirectUploadEnum.UserUploadedEpd,
  });

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      // continuing to support only one file for EPD upload for now -- we can adapt in the future
      const file = _.first(acceptedFiles);
      if (file) {
        performUpload([file]);
      }
    },
    [performUpload]
  );

  const onCancelClick = useCallback(() => {
    setFilename("");
    onFileRemove();
  }, [setFilename, onFileRemove]);

  return filename ? (
    <UploadedFile filename={filename} onCancel={onCancelClick} />
  ) : (
    <Dropzone
      onDrop={onDrop}
      accept={FILE_TYPE_LOOKUP[acceptFileTypes]}
      maxFiles={1}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />

          <Button
            disableElevation
            component="label"
            variant="contained"
            size="small"
            className="upload-button"
            startIcon={<UploadIcon />}
          >
            <div id="progress" ref={progressBarRef} />

            <span>
              {uploading
                ? "Uploading..."
                : isDragActive
                ? "Drop to upload"
                : `Choose your ${
                    acceptFileTypes === AllowedFileTypes.PDF ? "PDF" : "image"
                  } file`}
            </span>
          </Button>
        </div>
      )}
    </Dropzone>
  );
};

export default AttachFile;
