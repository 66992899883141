import { useFormContext } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import _ from "lodash";

import { AreaUnit } from "gql/graphql";
import FieldWithError from "components/FieldWithError";
import { GrossFloorAreaText } from "components/EditProjectForm/components/GrossFloorAreaText";
import { formatAreaUnit, safeComputeGFA } from "utils/formatting";

import "./FloorGroupsInputFields.scss";
import { CustomErrorMessages } from "pages/CreateProject/Components/Form/CustomErrorMessages";
import { customMessages } from "pages/CreateProject/GuidedProjectOnboarding/constants";
import { standardErrorMessage, ariaProps } from "utils/forms";

const floorAreaValidations = {
  pattern: "[0-9,]*",
  title: "Please enter positive, whole numbers only.",
  min: 100,
};

export const FloorGroupsInputFields = ({
  hideCalculation = false,
}: {
  hideCalculation?: boolean;
}) => {
  const {
    register,
    formState: { errors, isDirty },
    watch,
  } = useFormContext();

  const disableAnimation = !isDirty;
  const unit = watch("aboveGroundFloors.areaPerFloor.units");
  const grossFloorArea = safeComputeGFA(
    watch("aboveGroundFloors"),
    watch("belowGroundFloors")
  );

  return (
    <>
      <div className="floor-group-field">
        <FieldWithError
          className="floor-count above-ground-floor-count"
          fieldElement={
            <>
              <InputLabel htmlFor="aboveGroundFloors-count">
                Number of above ground floors
              </InputLabel>
              <TextField
                size="small"
                id="aboveGroundFloors-count"
                placeholder="Floors"
                type="number"
                inputProps={{
                  ...ariaProps(errors, "aboveGroundFloors.count"),
                  min: 1,
                }}
                {...register("aboveGroundFloors.count", {
                  required: true,
                  min: 1,
                  max: 110,
                })}
              />
              <CustomErrorMessages
                name="aboveGroundFloors.count"
                errors={errors}
                customMessages={customMessages.aboveGroundFloors.count}
              />
            </>
          }
        />
      </div>

      <div className="floor-group-field col2 floor-area-per-above-ground-floors">
        <InputLabel htmlFor="aboveGroundFloors-area">
          Floor area per above ground floors
        </InputLabel>
        <div className="fields floor-area-definition">
          <FieldWithError
            errorMessage={standardErrorMessage(
              errors,
              "aboveGroundFloors.areaPerFloor.area"
            )}
            fieldElement={
              <FormControl>
                <TextField
                  size="small"
                  id="aboveGroundFloors-area"
                  placeholder="Area"
                  inputProps={{
                    ...ariaProps(errors, "aboveGroundFloors.areaPerFloor.area"),
                    ...floorAreaValidations,
                  }}
                  InputLabelProps={{
                    disableAnimation,
                  }}
                  {...register("aboveGroundFloors.areaPerFloor.area", {
                    required: true,
                    min: 0,
                  })}
                  value={watch("aboveGroundFloors.areaPerFloor.area") || ""}
                />
                <CustomErrorMessages
                  name="aboveGroundFloors.areaPerFloor.area"
                  errors={errors}
                  customMessages={
                    customMessages.aboveGroundFloors.areaPerFloor.area
                  }
                />
              </FormControl>
            }
          />
          <FieldWithError
            errorMessage={standardErrorMessage(
              errors,
              "aboveGroundFloors.areaPerFloor.units"
            )}
            fieldElement={
              <FormControl>
                <Select
                  id="above-ground-floors-area-per-floor-units"
                  size="small"
                  inputProps={{
                    ...ariaProps(
                      errors,
                      "aboveGroundFloors.areaPerFloor.units"
                    ),
                  }}
                  {...register("aboveGroundFloors.areaPerFloor.units", {
                    required: true,
                  })}
                  value={unit || AreaUnit.Ft2}
                >
                  {_.map(AreaUnit, (use) => (
                    <MenuItem key={use} value={use}>
                      {formatAreaUnit(use)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          />
        </div>
      </div>

      <div className="floor-group-field row2 floor-count below-ground-floor-count">
        <FieldWithError
          fieldElement={
            <>
              <InputLabel htmlFor="belowGroundFloors-count">
                Number of below ground floors
              </InputLabel>
              <FieldWithError
                fieldElement={
                  <TextField
                    size="small"
                    id="belowGroundFloors-count"
                    placeholder="Floors"
                    type="number"
                    inputProps={{
                      ...ariaProps(errors, "belowGroundFloors.count"),
                      min: 0,
                    }}
                    helperText="Optional"
                    {...register("belowGroundFloors.count", {
                      min: 0,
                      max: 15,
                    })}
                  />
                }
              />
              <CustomErrorMessages
                name="belowGroundFloors.count"
                errors={errors}
                customMessages={customMessages.belowGroundFloors.count}
              />
            </>
          }
        />
      </div>

      <div className="floor-group-field row2 col2 floor-area-per-below-ground-floors">
        <InputLabel htmlFor="belowGroundFloors-area">
          Floor area per below ground floors
        </InputLabel>
        <div className="fields floor-area-definition">
          <FieldWithError
            fieldElement={
              <FormControl>
                <TextField
                  size="small"
                  id="belowGroundFloors-area"
                  placeholder="Area"
                  helperText="Optional"
                  inputProps={{
                    ...ariaProps(errors, "belowGroundFloors.areaPerFloor.area"),
                    ...floorAreaValidations,
                  }}
                  {...register("belowGroundFloors.areaPerFloor.area", {
                    min: 0,
                    pattern: new RegExp(floorAreaValidations.pattern),
                  })}
                  value={watch("belowGroundFloors.areaPerFloor.area") || ""}
                />
                <CustomErrorMessages
                  name="belowGroundFloors.areaPerFloor.area"
                  errors={errors}
                  customMessages={
                    customMessages.belowGroundFloors.areaPerFloor.area
                  }
                />
              </FormControl>
            }
          />
          <FieldWithError
            errorMessage={standardErrorMessage(
              errors,
              "belowGroundFloors.areaPerFloor.units"
            )}
            fieldElement={
              <FormControl>
                <Select
                  id="below-ground-floors-area-per-floor-units"
                  size="small"
                  defaultValue={AreaUnit.Ft2}
                  inputProps={{
                    ...ariaProps(
                      errors,
                      "belowGroundFloors.areaPerFloor.units"
                    ),
                  }}
                  {...register("belowGroundFloors.areaPerFloor.units")}
                  value={
                    watch("belowGroundFloors.areaPerFloor.units") ||
                    AreaUnit.Ft2
                  }
                >
                  {_.map(AreaUnit, (use) => (
                    <MenuItem key={use} value={use}>
                      {formatAreaUnit(use)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          />
        </div>
      </div>

      <div className="floor-group-field row3 floor-count parking-floor-count">
        <FieldWithError
          fieldElement={
            <>
              <InputLabel htmlFor="parkingFloors-count">
                Number of parking floors
              </InputLabel>
              <FieldWithError
                fieldElement={
                  <TextField
                    size="small"
                    id="parkingFloors-count"
                    placeholder="Floors"
                    type="number"
                    inputProps={{
                      ...ariaProps(errors, "parkingFloors.count"),
                      min: 0,
                    }}
                    helperText="Optional"
                    {...register("parkingFloors.count", { min: 0 })}
                  />
                }
              />
              <CustomErrorMessages
                name="parkingFloors.count"
                errors={errors}
                customMessages={customMessages.parkingFloors.count}
              />
            </>
          }
        />
      </div>

      <div className="floor-group-field row3 col2 floor-area-per-below-ground-floors">
        <InputLabel htmlFor="parkingFloors-area">
          Floor area per parking floors
        </InputLabel>
        <div className="fields floor-area-definition">
          <FieldWithError
            fieldElement={
              <FormControl>
                <TextField
                  size="small"
                  type="number"
                  id="parkingFloors-area"
                  placeholder="Area"
                  helperText="Optional"
                  inputProps={{
                    ...ariaProps(errors, "parkingFloors.areaPerFloor.area"),
                    ...floorAreaValidations,
                  }}
                  {...register("parkingFloors.areaPerFloor.area", {
                    pattern: new RegExp(floorAreaValidations.pattern),
                  })}
                  value={watch("parkingFloors.areaPerFloor.area") || ""}
                />
                <CustomErrorMessages
                  name="parkingFloors.areaPerFloor.area"
                  errors={errors}
                  customMessages={
                    customMessages.parkingFloors.areaPerFloor.area
                  }
                />
              </FormControl>
            }
          />
          <FieldWithError
            errorMessage={standardErrorMessage(
              errors,
              "parkingFloors.areaPerFloor.units"
            )}
            fieldElement={
              <FormControl>
                <Select
                  id="below-ground-floors-area-per-floor-units"
                  size="small"
                  defaultValue={AreaUnit.Ft2}
                  inputProps={{
                    ...ariaProps(errors, "parkingFloors.areaPerFloor.units"),
                  }}
                  {...register("parkingFloors.areaPerFloor.units")}
                  value={
                    watch("parkingFloors.areaPerFloor.units") || AreaUnit.Ft2
                  }
                >
                  {_.map(AreaUnit, (use) => (
                    <MenuItem key={use} value={use}>
                      {formatAreaUnit(use)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          />
        </div>
      </div>

      {!hideCalculation && (
        <div className="gross-floor-area row4">
          <p className="body-4">Gross Floor Area</p>
          <p className="gross-floor-area-text">
            <GrossFloorAreaText
              label={false}
              area={grossFloorArea}
              unit={unit}
            />
          </p>
        </div>
      )}
    </>
  );
};

export default FloorGroupsInputFields;
