import _ from "lodash";
import {
  FieldValues,
  UseFormClearErrors,
  UseFormSetValue,
} from "react-hook-form";

export const numberFormat = (num: number) => {
  const options = {
    style: "decimal", // Other options: 'currency', 'percent', etc.
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };
  return num.toLocaleString("en-US", options);
};

interface AddressComponents {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface GoogleMapsApiResponse {
  address_components: AddressComponents[];
  geometry: {
    location: { lat: () => number; lng: () => number };
  };
}

export interface GoogleMapsAddressComponents {
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  administrative_area_level_3: string;
  country: string;
  lat: number;
  lng: number;
  locality: string;
  postal_code: string;
  postal_town: string;
  route: string;
  street_number: string;
  sublocality: string;
  subpremise: string; // e.g. Apt 2 or Unit 3
}

export const extractAddressComponents = (
  googleMapsApi: GoogleMapsApiResponse
): GoogleMapsAddressComponents => {
  // these are the fields we need
  const entitiesToExtract = [
    "administrative_area_level_1",
    "administrative_area_level_2",
    "administrative_area_level_3",
    "country",
    "locality",
    "route",
    "subpremise",
    "postal_code",
    "postal_town",
    "street_number",
    "sublocality",
  ];
  const addressComponents = _.get(googleMapsApi, "address_components", []);

  const mappings = {} as any;
  for (const entity of entitiesToExtract) {
    _.each(addressComponents, ({ types, long_name, short_name }) => {
      if (_.includes(types, entity)) {
        if (_.includes(["country", "administrative_area_level_1"], entity)) {
          mappings[entity] = short_name;
        } else {
          mappings[entity] = long_name;
        }
      }
    });
  }
  return {
    ...mappings,
    lat: googleMapsApi.geometry.location?.lat(),
    lng: googleMapsApi.geometry.location?.lng(),
  } as GoogleMapsAddressComponents;
};

export const ingestGoogleAddress = (
  vals: GoogleMapsAddressComponents,
  setValue: UseFormSetValue<FieldValues>,
  clearErrors: UseFormClearErrors<FieldValues>
) => {
  setValue(
    "address.city",
    vals.sublocality ||
      vals.locality ||
      vals.postal_town ||
      vals.administrative_area_level_2 ||
      vals.administrative_area_level_3
  );
  setValue("address.postalCode", vals.postal_code);
  if (vals.country === "US" || vals.country === "CA") {
    setValue("address.stateCode", vals.administrative_area_level_1);
  } else {
    setValue("address.stateCode", null);
  }
  setValue("address.countryCode", vals.country);
  setValue("address.latitude", vals.lat);
  setValue("address.longitude", vals.lng);

  if (vals.street_number || vals.route) {
    setValue("address.street1", `${vals.street_number || ""} ${vals.route}`);
  } else {
    setValue("address.street1", null);
  }
  setValue("address.street2", vals.subpremise);

  clearErrors("address.postalCode");
};

export const geocodeAddress = async (
  postalCode: string,
  setValue: any,
  clearErrors: any
): Promise<{
  latitude: number;
  longitude: number;
}> /* throws if invalid */ => {
  const googleAPI = (window as any).google;

  if (!googleAPI) {
    throw new Error("window.google maps is undefined");
  }

  const { Geocoder } = googleAPI.maps;

  const { results } = await new Geocoder().geocode({
    address: postalCode.trim().toUpperCase(),
  });

  const firstMatchingResult = _.first(results) as GoogleMapsApiResponse;
  if (firstMatchingResult) {
    const addressComponents = extractAddressComponents(firstMatchingResult);
    ingestGoogleAddress(addressComponents, setValue, clearErrors);
    if (!addressComponents.lat || !addressComponents.lng) {
      throw new Error("No location for address");
    }

    return {
      latitude: addressComponents.lat,
      longitude: addressComponents.lng,
    };
  }
  throw new Error("Failed to geocode address");
};
