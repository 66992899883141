import { BaseSyntheticEvent, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { Plant } from "gql/graphql";

import AddressInputFields from "components/EditProjectForm/components/AddressInputFields";
import FieldWithError from "components/FieldWithError";
import { ariaProps, standardErrorMessage } from "utils/forms";
import "./CreatePlantModal.scss";

interface Props {
  handleClose: () => void;
  handleSubmit: (data: Partial<Plant>) => void;
  modalOpen: boolean;
  modalValue: Partial<Plant>;
}

const CreatePlantForm = ({
  modalOpen,
  handleClose,
  handleSubmit: handlePlantSubmit,
  modalValue,
}: Props) => {
  // This modal has it's own form to keep track of plant name and address, as well as errors,
  // as the user is creating a new plant
  // only when they submit the form will it set the plant on the parent EPD Uploadform
  const formProps = useForm<Partial<Plant>>({
    defaultValues: modalValue,
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = formProps;

  useEffect(() => {
    setValue("name", modalValue.name);
    setValue("address", modalValue.address);
  }, [modalValue, setValue]);

  const onSubmit = (
    data: Partial<Plant>,
    event: BaseSyntheticEvent<object, any, any> | undefined
  ) => {
    event?.preventDefault();
    handleClose();
    handlePlantSubmit(data); // this is where it will it set the plant on the parent EPD Uploadform
  };

  return (
    <Dialog
      fullWidth
      open={modalOpen}
      onClose={handleClose}
      id="create-plant-modal"
    >
      <FormProvider {...formProps}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Create new plant</DialogTitle>
          <DialogContent>
            <FieldWithError
              errorMessage={standardErrorMessage(errors, "name")}
              fieldElement={
                <TextField
                  autoFocus
                  fullWidth
                  label="Plant name"
                  type="text"
                  size="small"
                  error={!!errors.name}
                  inputProps={{ ...ariaProps(errors, "name") }}
                  {...register("name", { required: true })}
                />
              }
            />
            <AddressInputFields hideLabel />
          </DialogContent>
          <DialogActions>
            <Button disableElevation onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              disableElevation
              type="submit"
              variant="contained"
              className="primary"
            >
              Add
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default CreatePlantForm;
