import _ from "lodash";
import { InputAdornment, TextField, Typography } from "@mui/material";
import FieldWithError from "components/FieldWithError";
import { FieldErrors } from "react-hook-form";
import { ariaProps, standardErrorMessage } from "utils/forms";
import { ExternalLinks, LIFECYCLE_STAGES } from "../../../constants";
import "./LifecycleStageFields.scss";

const [requiredStages, optionalStages] = _.partition(
  LIFECYCLE_STAGES,
  "required"
);

interface Props {
  categoryDeclaredUnit: string;
  errors: FieldErrors;
  register: any;
}
export const AdditionalLifecycleStages = ({
  categoryDeclaredUnit,
  errors,
  register,
}: Props) => {
  return (
    <div id="additional-lifecycle-stages">
      <Typography variant="body2">
        <strong>Additional lifecycle stages </strong>
        (optional). A1-A5 are currently used to calculate carbon emissions, but
        we will be adding support for additional lifecycle stages soon. Learn
        more in our{" "}
        <a href={ExternalLinks.Methodology} target="_blank" rel="noreferrer">
          methodology
        </a>
        .
      </Typography>

      <fieldset>
        {_.map(optionalStages, (stage, idx) => (
          <LifecycleStageField
            {...stage}
            key={stage.value}
            idx={idx + _.size(requiredStages)} // we need this number to accurately reflect where in the array of all lifecycle stages it is, regardless of required / optional
            register={register}
            errors={errors}
            categoryDeclaredUnit={categoryDeclaredUnit}
          />
        ))}
      </fieldset>
    </div>
  );
};

export const RequiredLifecycleStageFields = ({
  errors,
  register,
  categoryDeclaredUnit,
}: Props) => (
  <>
    {_.map(requiredStages, (stage, idx) => (
      <LifecycleStageField
        {...stage}
        key={stage.value}
        categoryDeclaredUnit={categoryDeclaredUnit}
        errors={errors}
        idx={idx}
        register={register}
        required
      />
    ))}
  </>
);

interface LifecycleStageFieldProps {
  categoryDeclaredUnit: string;
  errors: FieldErrors;
  idx: number;
  label: string;
  register: any;
  value: string;
  required?: boolean;
}

const LifecycleStageField = ({
  categoryDeclaredUnit,
  errors,
  idx,
  label,
  register,
  required = false,
  value,
}: LifecycleStageFieldProps) => (
  <FieldWithError
    errorMessage={standardErrorMessage(
      errors,
      `carbon.totalImpactByStage.${idx}.kgCo2e`
    )}
    fieldElement={
      <TextField
        fullWidth
        id="gwp-input"
        label={`${value} (${label})`}
        variant="outlined"
        size="small"
        type="number"
        {...register(`carbon.totalImpactByStage.${idx}.kgCo2e`, {
          required,
          valueAsNumber: true,
        })}
        inputProps={{
          ...ariaProps(errors, `carbon.totalImpactByStage.${idx}.kgCo2e`),
        }}
        InputProps={{
          endAdornment: _.includes(["A1A2A3", "A4", "A5"], value) ? (
            <InputAdornment
              disableTypography
              position="end"
              sx={{ fontSize: 14 }}
            >
              kg CO₂e/
              {categoryDeclaredUnit}
            </InputAdornment>
          ) : null,
        }}
      />
    }
  />
);
