import { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { FormContainer } from "react-hook-form-mui";
import { useFormContext } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { DISABLE_DEFAULT_GRAPHQL_ERROR_HANDLING } from "ApolloClient/links";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material";

import ButtonWithSubmissionState, {
  SubmissionState,
} from "./ButtonWithSubmissionState";
import { GET_PROJECTS, GET_PROJECT_DETAILS } from "graphql/queries/projects";
import { UPDATE_PROJECT } from "graphql/mutations/projects";
import { Project, ProjectUpdateInput } from "../gql/graphql";
import { DATE_STRING, MM_YYYY_STRING } from "utils/formatting";
import PropertyDetailsFields from "./EditProjectForm/components/PropertyDetailsFields";
import FloorGroupsInputFields from "./EditProjectForm/components/FloorGroupsInputFields";
import { ProjectMetadataFields } from "./EditProjectForm/components/ProjectMetadataFields";
import AddressInputFields from "./EditProjectForm/components/AddressInputFields";
import { ServerErrors } from "./forms/ServerErrors";

import {
  processAddress,
  processFloorGroupDefinition,
  floorsToInput,
} from "pages/CreateProject/GuidedProjectOnboarding/utils";
import ProjectTimeline from "./ProjectTimeline";
import CustomTabPanel, { a11yProps } from "./CustomTabPanel";
import "./EditProjectForm.scss";

const projectToInput = (project?: Project) => {
  const input = {
    ..._.pick(project, [
      "stage",
      "description",
      "propertyUse",
      "structureType",
      "includeInPortfolio",
    ]),
    projectName: project?.name,
    startedAt: project?.startedAt ? dayjs(project?.startedAt) : undefined,
    completedAt: project?.completedAt ? dayjs(project?.completedAt) : undefined,
    address: {
      ...(project?.address || {}),
    },
    aboveGroundFloors: floorsToInput(project?.aboveGroundFloors),
    belowGroundFloors: floorsToInput(project?.belowGroundFloors),
    parkingFloors: floorsToInput(project?.parkingFloors),
  };

  return input;
};

const formToInput = (vals: FormInput) => {
  return _.omitBy(
    {
      ..._.omit(
        vals,
        "projectName",
        "address",
        "aboveGroundFloors",
        "belowGroundFloors",
        "parkingFloors"
      ),
      name: vals.projectName,
      completedAt: vals.completedAt
        ? dayjs(vals.completedAt, MM_YYYY_STRING).format(DATE_STRING)
        : undefined,
      startedAt: vals.startedAt
        ? dayjs(vals.startedAt, MM_YYYY_STRING).format(DATE_STRING)
        : undefined,
      address: processAddress(vals.address),
      aboveGroundFloors: processFloorGroupDefinition(vals.aboveGroundFloors),
      belowGroundFloors: processFloorGroupDefinition(vals.belowGroundFloors),
      parkingFloors: processFloorGroupDefinition(vals.parkingFloors),
    },
    _.isNil
  ) as ProjectUpdateInput;
};

type EditProjectFormProps = {
  afterSave: () => void;
  handleClose: () => void;
  project: Project;
};

export const EditProject = ({
  afterSave,
  project,
  ...rest
}: EditProjectFormProps) => {
  const [mutation] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [
      { query: GET_PROJECT_DETAILS, variables: { slug: project?.slug } },
      { query: GET_PROJECTS },
    ],
    context: { [DISABLE_DEFAULT_GRAPHQL_ERROR_HANDLING]: true },
  });
  const [serverErrors, setServerErrors] = useState<Error[]>([]);

  const onSuccess = async (vals: ProjectUpdateInput) => {
    try {
      setServerErrors([]);
      const result = await mutation({
        variables: {
          input: {
            project: {
              ...formToInput(vals),
              slug: project?.slug,
            },
          },
        },
      });
      const mutationResponses = _.values(_.get(result, "data.project"));
      const errorContainingObject = _.find(
        mutationResponses,
        (project) => project.errors
      );
      if (!_.isEmpty(errorContainingObject?.errors)) {
        setServerErrors(errorContainingObject.errors);
      } else {
        afterSave();
      }
    } catch (error) {
      setServerErrors([error as Error]);
    }
  };

  const defaultValues = useMemo(() => hydrateFormValues(project), [project]);

  return (
    <FormContainer
      FormProps={{
        id: "edit-project-form",
        className: "edit-project",
        autoComplete: "off",
      }}
      {...{ onSuccess, defaultValues }}
    >
      <ServerErrors {...{ serverErrors }} />
      <EditProjectForm {...{ project, serverErrors }} {...rest} />
    </FormContainer>
  );
};

type Props = {
  handleClose: () => void;
  project?: Project;
  serverErrors?: Error[];
};

// we use an alias for name so 1password etc don't try to autocomplete
type FormInput = ProjectUpdateInput & { projectName?: string };

const DEFAULT_VALUES = Object.freeze({
  projectName: "",
  structureType: "",
  propertyUse: "",
  address: Object.freeze({
    postalCode: "",
  }),
  stage: "",
}) as unknown as ProjectUpdateInput;

const hydrateFormValues = (project?: Project) =>
  _.merge(_.cloneDeep(DEFAULT_VALUES), projectToInput(project));

const tabNames = ["general", "customize"] as const;
type TabNames = (typeof tabNames)[number];

const EditProjectForm = ({ project, serverErrors, handleClose }: Props) => {
  const [currentTab, setCurrentTab] = useState<TabNames>("general");

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabNames) => {
    setCurrentTab(newValue);
  };

  const form = useFormContext();
  const { formState } = form;

  const { isSubmitted, isSubmitting, errors } = formState;

  const submissionState = useMemo(() => {
    if (isSubmitting) return SubmissionState.submitting;
    if (!_.isEmpty(errors) || !_.isEmpty(serverErrors)) {
      return SubmissionState.none;
    }
    if (isSubmitted) {
      return SubmissionState.success;
    }
    return SubmissionState.none;
  }, [isSubmitting, isSubmitted, errors, serverErrors]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="general details / customize"
        >
          <Tab
            disableRipple
            label="General"
            value={"general"}
            {...a11yProps<TabNames>({
              parentId: "edit-project-modal",
              tabName: "general",
            })}
          />
          <Tab
            disableRipple
            label="Customize"
            value="customize"
            {...a11yProps<TabNames>({
              parentId: "edit-project-modal",
              tabName: "customize",
            })}
          />
        </Tabs>
      </Box>

      <CustomTabPanel<TabNames>
        currentTab={currentTab}
        tabName="general"
        parentId="edit-project-modal"
      >
        <div className="grid">
          <ProjectMetadataFields />

          <AddressInputFields validateLatitude={false} />

          <PropertyDetailsFields />

          <ProjectTimeline hideLabel />
        </div>
      </CustomTabPanel>
      <CustomTabPanel<TabNames>
        currentTab={currentTab}
        tabName="customize"
        parentId="edit-project-modal"
      >
        <div id="floor-group-fields">
          <FloorGroupsInputFields />
        </div>
      </CustomTabPanel>

      <div className="submit-button-container">
        <Button
          disableElevation
          color="primary"
          onClick={handleClose}
          variant="text"
          className="no-underline"
        >
          Close
        </Button>
        <ButtonWithSubmissionState
          disabled={formState.isSubmitting || !_.isEmpty(errors)}
          uppercase={false}
          submissionState={submissionState}
          icons={{ [SubmissionState.none]: null }}
          entity={project ? "changes" : "project"}
        />
      </div>
    </Box>
  );
};

export const EditProjectDetailsModal = ({
  project,
  open,
  closeModal,
  refetchProject,
}: {
  closeModal: () => void;
  open: boolean;
  project: Project;
  refetchProject: () => void;
}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={closeModal}
      id="edit-project-modal"
      aria-labelledby="edit-project-modal-title"
      maxWidth="lg"
    >
      <DialogTitle id="edit-project-modal-title" color="primary">
        Edit project
      </DialogTitle>
      <DialogContent>
        <EditProject
          project={project}
          afterSave={() => {
            refetchProject();
            closeModal();
          }}
          handleClose={closeModal}
        />
      </DialogContent>
    </Dialog>
  );
};

{
  /* <ImageInputFields /> */
}
